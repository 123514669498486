import React, {useEffect, useState} from 'react'
import api from '../../api/api'
import MuiAlert from '@mui/material/Alert'
import {Snackbar} from '@material-ui/core'
import {navigate} from 'gatsby'
import {format} from 'date-fns'
import {Link} from 'gatsby'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const ProfileContent = ({user, setUser}) => {
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)

    const [editing, setEditing] = useState(false)
    const initialState = {
        id: '',
        name: '',
        surname: '',
        phone: '',
        email: '',
        idcard: '',
        birthday: '',
        type: '',
    };
    const [familiar, setFamiliar] = useState(initialState)
    const [familiars, setFamiliars] = useState([])

    const params = new URLSearchParams(window.location.search)
    const tab = params.get('tab')

    const [avatar, setAvatar] = useState(null)
    const [largeFamily, setLargeFamily] = useState(null)
    const [familyBook, setFamilyBook] = useState(null)
    const [disabilityCertificate, setDisabilityCertificate] = useState(null)
    const [idCard, setIdCard] = useState(null)

    var avatar_image = user.media.filter(media => media.collection_name === 'avatar');
    var largeFamily_image = user.media.filter(media => media.collection_name === 'largeFamily');
    var familyBook_image = user.media.filter(media => media.collection_name === 'familyBook');
    var disabilityCertificate_image = user.media.filter(media => media.collection_name === 'disabilityCertificate');
    var idCard_image = user.media.filter(media => media.collection_name === 'idCard');

    var disabilityCertificateFamiliar_image = null;
    var idCardFamiliar_image = null;

    const [validation, setValidation] = useState({
        email: null,
        password: null,
        password_confirmation: null,
        phone: null,
        name: null,
        birthday: null,
        surname: null,
        idcard: null,
    })
    const [validationFamiliar, setValidationFamiliar] = useState({
        email: null,
        phone: null,
        name: null,
        surname: null,
        idcard: null,
        birthday: null,
        type: null,
    })
    useEffect(() => {
        setFamiliars(user.familiars)
        if (tab !== null) {
            openTabSection(null, tab)
        }
    }, [])

    const onInputChange = e => {
        setError(null)
        setUser({...user, [e.target.name]: e.target.value})
        setValidation({...validation, [e.target.name]: null})
    }
    const onInputChangeFamiliar = e => {
        setError(null)
        // if(e.target.name === 'birthday' && !isNaN(new Date(e.target.value)) &&  new Date(e.target.value) < new Date("1920-01-01") ){
        //     setError("Fecha de nacimiento no válida")
        // }else{
        setFamiliar({...familiar, [e.target.name]: e.target.value})
        setValidationFamiliar({...validationFamiliar, [e.target.name]: null})
        // }

    }
    const handleClose = () => {
        setError(null)
    }
    const handleCloseSuccess = () => {
        setSuccess(null)
    }
    const handleChangeImage = e => {
        setAvatar(URL.createObjectURL(e.target.files[0]))
        setUser({...user, avatar: e.target.files[0]})
    }
    const handleChangeFamilyBook = e => {
        setFamilyBook(URL.createObjectURL(e.target.files[0]))
        setUser({...user, family_book: e.target.files[0]})
    }
    const handleChangeLargeFamily = e => {
        setLargeFamily(URL.createObjectURL(e.target.files[0]))
        setUser({...user, large_family: e.target.files[0]})
    }
    const handleChangeDisabilityCertificate = e => {
        setDisabilityCertificate(URL.createObjectURL(e.target.files[0]))
        setUser({...user, disability_certificate: e.target.files[0]})
    }
    const handleChangeIdCard = e => {
        setIdCard(URL.createObjectURL(e.target.files[0]))
        setUser({...user, id_card: e.target.files[0]})
    }
    const handleChangeDisabilityCertificateFamiliar = e => {
        setFamiliar({...familiar, disability_certificate: e.target.files[0]})
    }
    const handleChangeIdCardFamiliar = e => {
        setFamiliar({...familiar, id_card: e.target.files[0]})
    }
    const openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs_item");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].classList.remove("fadeInUp");
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByClassName("btn-link");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" current", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        document.getElementById(tabNmae).className += " fadeInUp animated";
        if (evt !== null) {

            evt.currentTarget.className += " current";
        } else {
            document.getElementById('btn-' + tabNmae).className += " current";
        }
    }
    var _getPropertyValue = function (object, notation) {
        // Get all the properties
        var properties = notation.split('.');

        // If we only have one property
        if (properties.length === 1) {
            // console.log(object);
            // console.log(properties);
            // Return our value
            return object[properties];
        }

        // Loop through our properties
        for (var property in object) {

            // Make sure we are a property
            if (object.hasOwnProperty(property)) {

                // If we our property name is the same as our first property
                if (property === properties[0]) {

                    // Remove the first item from our properties
                    properties.splice(0, 1);

                    // Create our new dot notation
                    var dotNotation = properties.join('.');

                    // Find the value of the new dot notation
                    return _getPropertyValue(object[property], dotNotation);
                }
            }
        }
    };
    function objLength(obj) {
        var i = 0;
        for (var x in obj) {
            if (obj.hasOwnProperty(x)) {
                i++;
            }
        }
        return i;
    }
    async function update() {
        setError(user.name === '' || user.surname === '' || user.phone === '' || user.idcard === '' || user.email === '' ? 'Rellena todos los campos' : null)
        setError(user.password !== user.password_confirmation ? 'Las contraseñas no coinciden' : null)
        if (user.password === user.password_confirmation && user.name !== '' && user.phone !== '' && user.idcard !== '' && user.email !== '') {
            setError(null)
            const data = new FormData()
            if (user.avatar) {
                data.append('avatar', user.avatar)
            }
            if (user.family_book) {
                data.append('family_book', user.family_book)
            }
            if (user.large_family) {
                data.append('large_family', user.large_family)
            }
            if (user.disability_certificate) {
                data.append('disability_certificate', user.disability_certificate)
            }
            if (user.id_card) {
                data.append('id_card', user.id_card)
            }
            data.append('name', user.name)
            data.append('surname', user.surname)
            data.append('phone', user.phone ? user.phone : '')
            data.append('idcard', user.idcard ? user.idcard : '')
            data.append('email', user.email)
            data.append('address', user.address ? user.address : '')
            data.append('birthday', user.birthday ? user.birthday : '')
            data.append('cif', user.cif ? user.cif : '')
            data.append('business_name', user.business_name ? user.business_name : '')
            if (user.password !== undefined && user.password_confirmation !== undefined) {
                data.append('password', user.password)
                data.append('password_confirmation', user.password_confirmation)
            }
            api.post(`${process.env.API_URL}/user`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.status === 201) {
                    localStorage.setItem('user', JSON.stringify(response.data))
                    setSuccess('Perfil guardado correctamente')

                    window.location.reload()
                } else {
                    setError('Se ha producido un error, contacte con la administración')
                }
            }).catch(error => {
                switch (error.response.status) {
                    case 401: // Not logged in
                    case 419: // Session expired

                        localStorage.removeItem('user')
                        window.dispatchEvent(new Event("removeUser"));
                        navigate('/signin')
                        break;

                    default:
                        try {
                            setValidation({
                                email: error.response.data.errors.email ? error.response.data.errors.email[0] : null,
                                password: error.response.data.errors.password ? error.response.data.errors.password[0] : null,
                                password_confirmation: error.response.data.errors.password ? error.response.data.errors.password[0] : null,
                                phone: error.response.data.errors.phone ? error.response.data.errors.phone[0] : null,
                                name: error.response.data.errors.name ? error.response.data.errors.name[0] : null,
                                surname: error.response.data.errors.surname ? error.response.data.errors.surname[0] : null,
                                idcard: error.response.data.errors.idcard ? error.response.data.errors.idcard[0] : null,
                                birthday: error.response.data.errors.birthday ? error.response.data.errors.birthday[0] : null,
                                cif: error.response.data.errors.cif ? error.response.data.errors.cif[0] : null,
                                business_name: error.response.data.errors.business_name ? error.response.data.errors.business_name[0] : null,
                                address: error.response.data.errors.address ? error.response.data.errors.address[0] : null,

                            })
                            setError('Revisa el formulario')
                        } catch (exceptionVar) {
                            setError('Se ha producido un error. Por favor, inténtelo de nuevo')
                        } finally {

                        }

                }

            })

        }
    }

    async function getPassbook() {
        api.get(`${process.env.API_URL}/passbook`, {
            responseType: 'arraybuffer'
        }).then(response => {
            const file = new Blob([response.data], {type: 'application/vnd.apple.pkpass'})
            const fileURL = URL.createObjectURL(file)
            const link = document.createElement('a')
            link.href = fileURL
            link.click()
        }).catch(error => {
            console.log(error.response.status);
            switch (error.response.status) {
                case 401: // Not logged in
                case 419: // Session expired

                    localStorage.removeItem('user')
                    window.dispatchEvent(new Event("removeUser"));
                    navigate('/signin')
                    break;

                default:
                    setError('Hubo un error al obtener el passbook, contacte con la administración')
            }

        }).finally(() => {
            setError(null)
        }
        )


    }
    function familiarItem(familiar) {

        setFamiliar(familiar)

        setEditing(true)
    }
    const sendFamiliar = () => {
        const data = new FormData()

        if (familiar.disability_certificate) {
            data.append('disability_certificate', familiar.disability_certificate)
        }
        if (familiar.id_card) {
            data.append('id_card', familiar.id_card)
        }

        data.append('id', familiar.id ? familiar.id : '')
        data.append('name', familiar.name)
        data.append('surname', familiar.surname)
        data.append('phone', familiar.phone ? familiar.phone : '')
        data.append('email', familiar.email ? familiar.email : '')
        data.append('idcard', familiar.idcard ? familiar.idcard : '')
        data.append('birthday', familiar.birthday ? familiar.birthday : '')
        data.append('type', familiar.type)

        api.post(`${process.env.API_URL}/familiar`, data).then(response => {
            setEditing(false)
            setSuccess('Familiar guardado correctamente')
            setFamiliar(initialState);
            setFamiliars(response.data.familiars)
        })
            .catch(error => {
                switch (error.response.status) {
                    case 401: // Not logged in
                    case 419: // Session expired

                        localStorage.removeItem('user')
                        window.dispatchEvent(new Event("removeUser"));
                        navigate('/signin')
                        break;

                    default:
                        setValidationFamiliar({
                            email: error.response.data.errors.email ? error.response.data.errors.email[0] : null,
                            phone: error.response.data.errors.phone ? error.response.data.errors.phone[0] : null,
                            name: error.response.data.errors.name ? error.response.data.errors.name[0] : null,
                            surname: error.response.data.errors.surname ? error.response.data.errors.surname[0] : null,
                            idcard: error.response.data.errors.idcard ? error.response.data.errors.idcard[0] : null,
                            birthday: error.response.data.errors.birthday ? error.response.data.errors.birthday[0] : null,
                            type: error.response.data.errors.type ? error.response.data.errors.type[0] : null,

                        })
                        setError('Revisa el formulario')
                }
            })

    }
    const cancelFamiliar = () => {
        setFamiliar(initialState);
        setEditing(false)
    }
    return (
        <div className='services-details-area pb-100'>
            <div className='container'>
                <div className='services-details-desc'>
                    <ul className='nav nav-tabs row' id='myTab'>
                        <li key={1} className='col-md-4 col-12 m-0'>
                            <button
                                id='btn-tab1'
                                className='btn btn-link current'
                                onClick={(e) => openTabSection(e, 'tab1')}>
                                Perfil
                            </button>
                        </li>
                        <li key={2} className='col-md-4 col-12 m-0'>
                            <button
                                id='btn-tab2'
                                className='btn btn-link'
                                onClick={(e) => openTabSection(e, 'tab2')}>
                                Familiares
                            </button>
                        </li>
                        <li key={3} className='col-md-4 col-12 m-0'>
                            <button
                                id='btn-tab3'
                                className='btn btn-link'
                                onClick={(e) => openTabSection(e, 'tab3')}>
                                Documentos
                            </button>
                        </li>
                    </ul>
                    <div className='tab-content' style={{paddingTop: '10px', paddingLeft: '0px', paddingRight: '0px'}}>
                        <div id='tab1' className='tab-pane tabs_item'>
                            <div className='row'>

                                <div className='mb-5 text-center col-12 col-lg-3'>
                                    <button className='mb-5 button-secondary w-100' onClick={getPassbook}><i className='flaticon-right'></i> Obtener Tarjeta Ciudadana</button>
                                    <div className="profile-image w-100">
                                        <div className="label">
                                            {avatar_image.length > 0 || avatar ?
                                                <>
                                                    <img src={avatar ?? avatar_image[0].original_url} alt="Imagen de perfil" width={250} />
                                                    <div className="overlay">
                                                        <div className="text">Subir imagen</div>
                                                    </div>
                                                </>
                                                :
                                                <label htmlFor='image' className='profile-avatar'>Subir imagen de perfil</label>
                                            }
                                        </div>
                                        <input type="file" id="image" name="image" accept="image/*" className="w-100" onChange={handleChangeImage} />
                                    </div>
                                </div>
                                <div className='col-12 col-lg-9'>
                                    <div className='profile-form'>
                                        <form>
                                            <div className='form-group'>
                                                <label htmlFor='name'>Nombre</label>
                                                <input type='text' name='name' className='form-control' placeholder='Nombre' value={user.name} onChange={e => onInputChange(e)} />
                                                {validation.name && <span className='text-danger'>{validation.name}</span>}
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='name'>Apellidos</label>
                                                <input type='text' name='surname' className='form-control' placeholder='Apellidos' value={user.surname} onChange={e => onInputChange(e)} />
                                                {validation.surname && <span className='text-danger'>{validation.surname}</span>}
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='email'>Correo Electrónico</label>
                                                <input type='email' name='email' className='form-control' placeholder='Correo Electrónico' value={user.email} onChange={e => onInputChange(e)} />
                                                {validation.email && <span className='text-danger'>{validation.email}</span>}
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='phone'>Número de identificación (DNI)</label>
                                                <input type='text' name='idcard' className='form-control' placeholder='Número de identificación' value={user.idcard} onChange={e => onInputChange(e)} />
                                                {validation.idcard && <span className='text-danger'>{validation.idcard}</span>}
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='phone'>Teléfono</label>
                                                <input type='phone' name='phone' className='form-control' placeholder='Teléfono' value={user.phone} onChange={e => onInputChange(e)} />
                                                {validation.phone && <span className='text-danger'>{validation.phone}</span>}
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='birthday'>Fecha de Nacimiento</label>
                                                <input type='date' name='birthday' className='form-control' placeholder='Fecha de nacimiento' value={user.birthday} onChange={e => onInputChange(e)} />
                                                {validation.birthday && <span className='text-danger'>{validation.birthday}</span>}
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='address'>Dirección</label>
                                                <input type='address' name='address' className='form-control' placeholder='Dirección' value={user.address} onChange={e => onInputChange(e)} />
                                                {validation.address && <span className='text-danger'>{validation.address}</span>}
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='cif'>CIF</label>
                                                <input type='cif' name='cif' className='form-control' placeholder='CIF' value={user.cif} onChange={e => onInputChange(e)} />
                                                {validation.cif && <span className='text-danger'>{validation.cif}</span>}
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='business_name'>Nombre de empresa</label>
                                                <input type='business_name' name='business_name' className='form-control' placeholder='Nombre de empresa' value={user.business_name} onChange={e => onInputChange(e)} />
                                                {validation.business_name && <span className='text-danger'>{validation.business_name}</span>}
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='password'>Contraseña</label>
                                                <input type="password" className="passwordFake" placeholder="Enter Password" name="passwordFake" />
                                                <input type='password' name='password' className='form-control' placeholder='Contraseña' autoComplete='off' onChange={e => onInputChange(e)} />
                                                {validation.password && <span className='text-danger'>{validation.password}</span>}
                                            </div>
                                            <div className='form-group'>
                                                <label htmlFor='password'>Confirmar contraseña</label>
                                                <input type='password' name='password_confirmation' className='form-control' placeholder='Confirmar contraseña' autoComplete='off' onChange={e => onInputChange(e)} />
                                                {validation.password_confirmation && <span className='text-danger'>{validation.password_confirmation}</span>}
                                            </div>
                                            <button type='button' onClick={update}>Actualizar</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id='tab2' className='tab-pane tabs_item'>
                            {editing ?
                                null
                                :
                                <>
                                    <div className='row'>

                                        <div className='col-sm-12 col-md-8'>

                                        </div>
                                        <div className='col-sm-12 col-md-4'>
                                            <button className='my-btn-primary w-100 float-end' style={{marginTop: '5px'}} onClick={() => setEditing(true)}>Añadir</button>

                                        </div>
                                    </div>
                                </>
                            }
                            <section className="solutions-area pt-5">
                                <div className="container">
                                    {editing ?
                                        <>
                                            <div className='row'>

                                                <div className='col-12'>
                                                    <div className='profile-form'>
                                                        <form>
                                                            <div className='form-group'>
                                                                <label htmlFor='type'>Añada los miembros que figuren en su libro de familia u otros familiares, a excepción de usted mismo</label>
                                                                <select className="form-select w-100" name="type" value={familiar.type} onChange={e => onInputChangeFamiliar(e)}>
                                                                    <option key="relation" value="">
                                                                        Seleccione una opción
                                                                    </option>
                                                                    <option key="relation0" value="0">
                                                                        Cotitular del Libro de Familia
                                                                    </option>
                                                                    <option key="relation1" value="1">
                                                                        Hijo / Hija
                                                                    </option>
                                                                    <option key="relation2" value="2">
                                                                        Otros
                                                                    </option>
                                                                </select>
                                                                {validationFamiliar.type && <span className='text-danger'>{validationFamiliar.type}</span>}
                                                            </div>
                                                            <div className='form-group'>
                                                                <label htmlFor='name'>Nombre</label>
                                                                <input type='text' name='name' className='form-control' placeholder='Nombre' value={familiar.name} onChange={e => onInputChangeFamiliar(e)} />
                                                                {validationFamiliar.name && <span className='text-danger'>{validationFamiliar.name}</span>}
                                                            </div>
                                                            <div className='form-group'>
                                                                <label htmlFor='name'>Apellidos</label>
                                                                <input type='text' name='surname' className='form-control' placeholder='Apellidos' value={familiar.surname} onChange={e => onInputChangeFamiliar(e)} />
                                                                {validationFamiliar.surname && <span className='text-danger'>{validationFamiliar.surname}</span>}
                                                            </div>
                                                            <div className='form-group'>
                                                                <label htmlFor='email'>Correo Electrónico</label>
                                                                <input type='email' name='email' className='form-control' placeholder='Correo Electrónico' value={familiar.email} onChange={e => onInputChangeFamiliar(e)} />
                                                                {validationFamiliar.email && <span className='text-danger'>{validationFamiliar.email}</span>}
                                                            </div>
                                                            <div className='form-group'>
                                                                <label htmlFor='phone'>Número de identificación (DNI)</label>
                                                                <input type='text' name='idcard' className='form-control' placeholder='Número de identificación' value={familiar.idcard} onChange={e => onInputChangeFamiliar(e)} />
                                                                {validationFamiliar.idcard && <span className='text-danger'>{validationFamiliar.idcard}</span>}
                                                            </div>
                                                            <div className='form-group'>
                                                                <label htmlFor='phone'>Teléfono</label>
                                                                <input type='phone' name='phone' className='form-control' placeholder='Teléfono' value={familiar.phone} onChange={e => onInputChangeFamiliar(e)} />
                                                                {validationFamiliar.phone && <span className='text-danger'>{validationFamiliar.phone}</span>}
                                                            </div>
                                                            <div className='form-group'>
                                                                <label htmlFor='birthday'>Fecha de Nacimiento</label>
                                                                <input type='date' name='birthday' className='form-control' placeholder='Fecha de nacimiento' min="1920-01-01" value={familiar.birthday} onChange={e => onInputChangeFamiliar(e)} />
                                                                {validationFamiliar.birthday && <span className='text-danger'>{validationFamiliar.birthday}</span>}
                                                            </div>
                                                            <div className='form-group'>
                                                                <label htmlFor='birthday'>Número de identificación (DNI) (Pdf/Imagen)</label>
                                                                <input type="file" id="image" name="image" className='p-5' onChange={handleChangeIdCardFamiliar} />
                                                                {familiar.id && familiar.media.filter(media => media.collection_name === 'idCard').length > 0 ?
                                                                    <>
                                                                        <br />
                                                                        <Link to={familiar.media.filter(media => media.collection_name === 'idCard')[0].original_url} target='_blank' className='w-100 text-center d-block'>
                                                                            <i className='bx bx-file'></i>
                                                                        </Link>
                                                                    </>
                                                                    : null
                                                                }
                                                            </div>
                                                            <div className='form-group'>
                                                                <label htmlFor='birthday'>Certificado de discapacidad (Pdf/Imagen)</label>
                                                                <input type="file" id="image" name="image" className='p-5' onChange={handleChangeDisabilityCertificateFamiliar} />
                                                                {familiar.id && familiar.media.filter(media => media.collection_name === 'disabilityCertificate').length > 0 ?
                                                                    <>
                                                                        <br />
                                                                        <Link to={familiar.media.filter(media => media.collection_name === 'disabilityCertificate')[0].original_url} target='_blank' className='w-100 text-center d-block'>
                                                                            <i className='bx bx-file'></i>
                                                                        </Link>
                                                                    </>
                                                                    : null
                                                                }
                                                            </div>

                                                        </form>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>

                                                <div className='col-6'>
                                                    <button type='button' className="my-btn-primary" onClick={sendFamiliar}>Guardar</button>
                                                </div>
                                                <div className='col-6'>
                                                    <button type='button' className="my-btn-secondary" onClick={cancelFamiliar}>Cancelar</button>
                                                </div>
                                            </div>

                                        </>
                                        :
                                        <>

                                            {familiars.length > 0 ?
                                                <>
                                                    <div className="row">
                                                        {familiars.map((fam) => (
                                                            <>
                                                                <div className="col-lg-4 col-md-6 col-sm-6">
                                                                    <div className="single-solutions-box">
                                                                        <div className="icon">
                                                                            <i className='bx bx-user'></i>
                                                                        </div>
                                                                        <h3>{fam.full_name}</h3>
                                                                        <p>{fam.email ? fam.email + ' ·' : null} {fam.phone ? fam.phone + ' ·' : null} {fam.idcard ? fam.idcard : null}</p>
                                                                        {fam.status == 1 ?
                                                                            <>  <p className='badge rounded-pill bg-success'>Verificado</p></>
                                                                            :
                                                                            <>
                                                                                <button type='button' className="my-btn-small-secondary" onClick={() => familiarItem(fam)}>Editar</button>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </>
                                                        ))}


                                                    </div>





                                                </>
                                                :
                                                <p>No tienes familiares</p>
                                            }
                                        </>
                                    }

                                </div>
                            </section>

                        </div>
                        <div id='tab3' className='tab-pane tabs_item'>

                            <table className="table table-sm table-responsive text-center" style={{verticalAlign: 'middle'}}>
                                <thead>
                                    <tr>
                                        <th>Documento</th>
                                        <th>Estado</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            Documento de Identidad (DNI / NIE)
                                            {idCard_image.length > 0 ?
                                                <>
                                                    <br />
                                                    <Link to={idCard_image[0].original_url} target='_blank' className='w-100 text-center d-block'>
                                                        <i className='bx bx-file'></i>
                                                    </Link>
                                                </>
                                                : null
                                            }

                                        </td>
                                        <td>
                                            {idCard_image.length > 0 ?
                                                (
                                                    objLength(_getPropertyValue(idCard_image[0], 'custom_properties')) > 0 && _getPropertyValue(idCard_image[0], 'custom_properties')['status'] !== undefined ?
                                                        (
                                                            parseInt(_getPropertyValue(idCard_image[0], 'custom_properties')['status']) === 1 ?
                                                                <>
                                                                    <p className='badge rounded-pill bg-success'>Verificado</p>

                                                                </>
                                                                :
                                                                <>
                                                                    <p className='badge rounded-pill bg-danger'>Rechazado</p>

                                                                </>
                                                        )
                                                        :
                                                        <p className='badge rounded-pill bg-secondary'>Pendiente</p>


                                                ) : null
                                            }
                                            {idCard_image.length > 0 ?
                                                (
                                                    objLength(_getPropertyValue(idCard_image[0], 'custom_properties')) > 0 && _getPropertyValue(idCard_image[0], 'custom_properties')['expire_date'] !== undefined ?
                                                        <><br /></> &&
                                                        format(new Date(_getPropertyValue(idCard_image[0], 'custom_properties')['expire_date']), 'dd/MM/yyyy HH:mm')
                                                        :
                                                        null
                                                ) : null

                                            }
                                        </td>

                                        <td>
                                            <div className="text-center w-100">

                                                {idCard_image.length > 0 ?
                                                    (
                                                        objLength(_getPropertyValue(idCard_image[0], 'custom_properties')) > 0 && parseInt(_getPropertyValue(idCard_image[0], 'custom_properties')['status']) === 1 ?
                                                            null
                                                            :
                                                            <input type="file" id="image" name="image" className='p-5' onChange={handleChangeIdCard} />


                                                    ) : <input type="file" id="" name="image" className='p-5' onChange={handleChangeIdCard} />


                                                }
                                            </div>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Libro de Familia
                                            {familyBook_image.length > 0 ?
                                                <>
                                                    <br />
                                                    <Link to={familyBook_image[0].original_url} target='_blank' className='w-100 text-center d-block'>
                                                        <i className='bx bx-file'></i>
                                                    </Link>
                                                </>
                                                : null
                                            }

                                        </td>
                                        <td>
                                            {familyBook_image.length > 0 ?
                                                (
                                                    objLength(_getPropertyValue(familyBook_image[0], 'custom_properties')) > 0 && _getPropertyValue(familyBook_image[0], 'custom_properties')['status'] !== undefined ?
                                                        (
                                                            parseInt(_getPropertyValue(familyBook_image[0], 'custom_properties')['status']) === 1 ?
                                                                <>
                                                                    <p className='badge rounded-pill bg-success'>Verificado</p>

                                                                </>
                                                                :
                                                                <>
                                                                    <p className='badge rounded-pill bg-danger'>Rechazado</p>

                                                                </>
                                                        )
                                                        :
                                                        <p className='badge rounded-pill bg-secondary'>Pendiente</p>


                                                ) : null
                                            }
                                            {familyBook_image.length > 0 ?
                                                (
                                                    objLength(_getPropertyValue(familyBook_image[0], 'custom_properties')) > 0 && _getPropertyValue(familyBook_image[0], 'custom_properties')['expire_date'] !== undefined ?
                                                        <><br /></> &&
                                                        format(new Date(_getPropertyValue(familyBook_image[0], 'custom_properties')['expire_date']), 'dd/MM/yyyy HH:mm')
                                                        :
                                                        null
                                                ) : null

                                            }
                                        </td>

                                        <td>
                                            <div className="text-center w-100">

                                                {familyBook_image.length > 0 ?
                                                    (
                                                        objLength(_getPropertyValue(familyBook_image[0], 'custom_properties')) > 0 && parseInt(_getPropertyValue(familyBook_image[0], 'custom_properties')['status']) === 1 ?
                                                            null
                                                            :
                                                            <input type="file" id="image" name="image" className='p-5' onChange={handleChangeFamilyBook} />


                                                    ) : <input type="file" id="image" name="image" className='p-5' onChange={handleChangeFamilyBook} />


                                                }
                                            </div>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Título de familia numerosa
                                            {largeFamily_image.length > 0 ?
                                                <>
                                                    <br />
                                                    <Link to={largeFamily_image[0].original_url} target='_blank' className='w-100 text-center d-block'>
                                                        <i className='bx bx-file'></i>
                                                    </Link>
                                                </>
                                                : null
                                            }

                                        </td>
                                        <td>
                                            {largeFamily_image.length > 0 ?
                                                (
                                                    objLength(_getPropertyValue(largeFamily_image[0], 'custom_properties')) > 0 && _getPropertyValue(largeFamily_image[0], 'custom_properties')['status'] !== undefined ?
                                                        (
                                                            parseInt(_getPropertyValue(largeFamily_image[0], 'custom_properties')['status']) === 1 ?
                                                                <>
                                                                    <p className='badge rounded-pill bg-success'>Verificado</p>

                                                                </>
                                                                :
                                                                <>
                                                                    <p className='badge rounded-pill bg-danger'>Rechazado</p>

                                                                </>
                                                        )
                                                        :
                                                        <p className='badge rounded-pill bg-secondary'>Pendiente</p>


                                                ) : null
                                            }
                                            {largeFamily_image.length > 0 ?
                                                (
                                                    objLength(_getPropertyValue(largeFamily_image[0], 'custom_properties')) > 0 && _getPropertyValue(largeFamily_image[0], 'custom_properties')['expire_date'] !== undefined ?
                                                        <><br /></> &&
                                                        format(new Date(_getPropertyValue(largeFamily_image[0], 'custom_properties')['expire_date']), 'dd/MM/yyyy HH:mm')
                                                        :
                                                        null
                                                ) : null

                                            }
                                        </td>

                                        <td>
                                            <div className="text-center w-100">

                                                {largeFamily_image.length > 0 ?
                                                    (
                                                        objLength(_getPropertyValue(largeFamily_image[0], 'custom_properties')) > 0 && parseInt(_getPropertyValue(largeFamily_image[0], 'custom_properties')['status']) === 1 ?
                                                            null
                                                            :
                                                            <input type="file" id="image" name="image" className='p-5' onChange={handleChangeLargeFamily} />


                                                    ) : <input type="file" id="image" name="image" className='p-5' onChange={handleChangeLargeFamily} />


                                                }
                                            </div>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Certificado de Discapacidad
                                            {disabilityCertificate_image.length > 0 ?
                                                <>
                                                    <br />
                                                    <Link to={disabilityCertificate_image[0].original_url} target='_blank' className='w-100 text-center d-block'>
                                                        <i className='bx bx-file'></i>
                                                    </Link>
                                                </>
                                                : null
                                            }

                                        </td>
                                        <td>
                                            {disabilityCertificate_image.length > 0 ?
                                                (
                                                    objLength(_getPropertyValue(disabilityCertificate_image[0], 'custom_properties')) > 0 && _getPropertyValue(disabilityCertificate_image[0], 'custom_properties')['status'] !== undefined ?
                                                        (
                                                            parseInt(_getPropertyValue(disabilityCertificate_image[0], 'custom_properties')['status']) === 1 ?
                                                                <>
                                                                    <p className='badge rounded-pill bg-success'>Verificado</p>

                                                                </>
                                                                :
                                                                <>
                                                                    <p className='badge rounded-pill bg-danger'>Rechazado</p>

                                                                </>
                                                        )
                                                        :
                                                        <p className='badge rounded-pill bg-secondary'>Pendiente</p>


                                                ) : null
                                            }
                                            {disabilityCertificate_image.length > 0 ?
                                                (
                                                    objLength(_getPropertyValue(disabilityCertificate_image[0], 'custom_properties')) > 0 && _getPropertyValue(disabilityCertificate_image[0], 'custom_properties')['expire_date'] !== undefined ?
                                                        <><br /></> &&
                                                        format(new Date(_getPropertyValue(disabilityCertificate_image[0], 'custom_properties')['expire_date']), 'dd/MM/yyyy HH:mm')
                                                        :
                                                        null
                                                ) : null

                                            }
                                        </td>

                                        <td>
                                            <div className="text-center w-100">

                                                {disabilityCertificate_image.length > 0 ?
                                                    (
                                                        objLength(_getPropertyValue(disabilityCertificate_image[0], 'custom_properties')) > 0 && parseInt(_getPropertyValue(disabilityCertificate_image[0], 'custom_properties')['status']) === 1 ?
                                                            null
                                                            :
                                                            <input type="file" id="image" name="image" className='p-5' onChange={handleChangeDisabilityCertificate} />


                                                    ) : <input type="file" id="image" name="image" className='p-5' onChange={handleChangeDisabilityCertificate} />


                                                }
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='row'>

                                <div className='col-sm-12 col-md-8'>

                                </div>
                                <div className='col-sm-12 col-md-4'>
                                    <button type='button' className="my-btn-primary" onClick={update}>Actualizar</button>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Snackbar open={error !== null} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity='error' sx={{width: '100%'}}>
                    {error}
                </Alert>
            </Snackbar>
            <Snackbar open={success !== null} autoHideDuration={6000} onClose={handleCloseSuccess}>
                <Alert onClose={handleCloseSuccess} severity='success' sx={{width: '100%'}}>
                    {success}
                </Alert>
            </Snackbar>
        </div>

    )
}

export default ProfileContent