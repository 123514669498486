import React, {useEffect, useState} from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import ProfileContent from '../components/Profile/ProfileContent'
import {navigate} from 'gatsby'
import api from '../api/api'

const Profile = () => {
    const [user, setUser] = useState(null)

    useEffect(() => {
        (async () => {
            try {
                api(`${process.env.API_URL}/user`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    setUser(response.data)
                    localStorage.setItem('user', JSON.stringify(response.data))
                }).catch(error => {

                    localStorage.removeItem('user')
                    window.dispatchEvent(new Event("removeUser"));
                    navigate('/')
                })

            } catch (error) {
                localStorage.removeItem('user')
                window.dispatchEvent(new Event("removeUser"));
                navigate('/signin')
            }
        })()
    }, [])

    if (!user) return null

    return (
        <Layout title="Mi perfil">
            <PageBanner
                pageTitle='Perfil'
                homePageText='Inicio'
                homePageUrl='/'
                activePageText='Perfil'
            />
            <ProfileContent
                user={user}
                setUser={setUser}
            />
        </Layout>
    );
}
export default Profile;
export function Head() {
    return (
        <title>Ayuntamiento de Villafranca - Mi perfil</title>
    )
}